import { AbstractComponent } from '@components/generic/abstract.component';
import {
  AfterViewInit,
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  Renderer2, TemplateRef,
  ViewChild
} from '@angular/core';
import { AuthService } from '@services/auth.service';
import { AbstractResource, IRequestOptions } from '@resources/abstract.resource';
import { IHydraResource } from '@interfaces/hydra-resource.interface';
import { SessionHelper } from '@helpers/session.helper';
import { UrlHelper } from '@helpers/UrlHelper';
import { IColumnSortedEvent } from '@components/generic/List/services/sort.service';
import { forkJoin } from 'rxjs/observable/forkJoin';
import { SnackbarService } from '@components/snackbar';
import { IImportOptions } from '@interfaces/ImportOptions.interface';
import { IExportOptions } from '@interfaces';

/**
 * The list component is used in the list page for displaying:
 * - a filter form component to make filtered research and exporting files,
 * - an hydra pagination,
 * - a generic result list to display the data.
 *
 * Its responsibility is to deal with the api.
 * Result list components (generic or custom) have just to emit events for desired actions.
 * In this way actions like fetching, deleting, filtering, sorting are handle automatically.
 *
 * Features:
 * - choose if we want to use the filter form using `withFilters` then save the filters in storage according to `keepFiltersInStorage`.
 * - pass to it a custom result list component. @see: 'categories-marketplace-result-list.component.ts'
 */
@Component({
  selector: 'app-generic-list',
  template: require('./generic-list.component.html'),
})
export class GenericListComponent extends AbstractComponent implements OnInit, AfterViewInit {

  @ViewChild('resultListContainer') resultListContainer: ElementRef;

  /**
   * The custom list accessible by transclusion in a generic way.
   */
  @ContentChild('resultList') resultList: any;

  public items: IHydraResource|any;
  public replaceDefaultList: boolean = false;
  public isHydra: boolean;
  public currentState: string;
  public totalItems: number;

  @Input() public withFilters = true;
  @Input() public importOptions: IImportOptions[] = [];
  @Input() public exportOptions: IExportOptions[] = [];
  @Input() public extraOptions: any = {};
  @Input() public keepFiltersInStorage = true;
  @Input() public usePagination = true;
  @Input() public membersAccessor: string = null;
  @Input() public loadOnInit: boolean = true;
  @Input() public loadWithFormFilters: boolean = false;
  @Input() public rowTemplate?: TemplateRef<any> = null;

  /**
   * If filled, will be use instead of default getMany() entryPoint.
   */
  @Input() public getManyEntryPoint: string;
  @Input() public getManyParams: any;

  @Output() public onCustomDelete: EventEmitter<any> = new EventEmitter();
  @Output() public onFetchFilters: EventEmitter<any> = new EventEmitter();

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: AbstractResource,
    @Inject('StateService') state: ng.ui.IStateService,
    private renderer: Renderer2,
    private urlHelper: UrlHelper,
    private snackbar: SnackbarService,
  ) {
    super($translate, authService, resource, state);
  }

  ngOnInit(): void {
    this.currentState = (<any>this.state.$current).parent.self.name;
    this.isHydra = this.resource.isHydra;

    if (this.loadOnInit) {
      this.fetch();
    }
  }

  /**
   * We display the generic result list by default.
   * If it's the case we have to hide the container of the custom list.
   */
  ngAfterViewInit(): void {
    if (this.loadWithFormFilters && this.withFilters) {
      const form: HTMLFormElement = <HTMLFormElement>document.getElementById('dynamic-form');

      if (form) {
        const button: HTMLButtonElement = <HTMLButtonElement>form.querySelector('button[type="submit"]');

        if (button) {
          button.click();
        }
      }
    }

    const elem = this.resultListContainer.nativeElement;

    this.replaceDefaultList = !!elem.children.length;

    // if the default result list is displayed, we had this hack to hide the transclude container
    if (!this.replaceDefaultList) {
      this.renderer.addClass(elem, 'hidden');
    }

    // subscribe to output `onSortList` event from ContentChild when using custom result list
    if (this.resultList && this.resultList.onSortList) {
      this.resultList.onSortList.subscribe((item: IColumnSortedEvent) => this.sortResult(item));
    }
  }

  /**
   * Fetch data with potentially filters.
   * Saves filters in the storage according to `keepFiltersInStorage` in the complete subscription, then update url.
   */
  private fetch(filters?: object) {
    const params = this.getParams(filters);
    this.onFetchFilters.emit(params);
    const options: IRequestOptions = this.getManyEntryPoint ? { 'entryPoint': this.getManyEntryPoint } : {};

    this.resource.cGet(params, options)
      .takeUntil(this.destroyed$)
      .subscribe((response: IHydraResource|any) => {
        if (this.isHydra) {
          this.items = response['hydra:member'];
          this.totalItems = response['hydra:totalItems'];

          return;
        }

        // If the resource is not hydra but we want to use still the hydra pagination (that work in the same way)
        if (undefined !== +response.total) {
          this.totalItems = +response.total;
        }

        if (this.membersAccessor) {
          this.items = response[this.membersAccessor];
          return;
        }

        this.items = response;
      },
        undefined,
        () => {
          if (this.keepFiltersInStorage) {
            SessionHelper.setFiltersForPage(params, this.currentState);
          }

          // Sets the items property in a ContentChild when using custom result list
          if (this.resultList) {
            this.resultList.items = this.items;
          }

          this.state.go(this.state.current, params, { location: true, notify: false });
        }
      )
    ;
  }

  private getParams(filters: object): object {
    let params = { ...this.resource.cGetDefaultFilters() };

    if (this.getManyParams) {
      params = { ...this.getManyParams, ...params };
    }

    // If two tabs are open and user switch on another country in one of these tabs, we must to use the default filters.
    if (1 === SessionHelper.get('switchingCountry')) {
      if (filters) {
        SessionHelper.set('switchingCountry', 0);
      }

      return params;
    }

    /**
     * Rules:
     *  1 - If user make research, we take its filters, the potentially state params (pagination) and the default params
     *  2 - If user arrives on the page without query params and `keepFiltersInStorage` is true, we check in the storage for filters
     *      and add potentially default filters
     *  3 - If the user come with filters in url, we take them by the state params and add default filters
     *  4 - If conditions above are not true, default filters are used.
     */
    if (filters) {
      return { ...params, ...this.state.params, ...filters };
    } else if (undefined === this.urlHelper.getParameters() && this.keepFiltersInStorage) {
      return { ...params, ...SessionHelper.getFiltersForPage(this.currentState) };
    } else if (undefined !== this.urlHelper.getParameters()) {
      return { ...params, ...this.state.params };
    }

    return params;
  }

  /**
   *  Paginate must refresh the list with keeping the other filters from state params.
   */
  public paginate(event: {page: number, byPage: number}): void {
    this.fetch(event);
  }

  /**
   * Sorts the items.
   */
  public sortResult(event: IColumnSortedEvent) {
    switch (event.sortDirection) {
      case 'asc':
        this.fetch({ [`sort_${event.sortColumn}`]: 'asc' });
        break;
      case 'desc':
        this.fetch({ [`sort_${event.sortColumn}`]: 'desc' });
        break;
      case 'none':
        this.fetch({ [`sort_${event.sortColumn}`]: null });
        break;
    }
  }

  /**
   * Bulk delete, is triggered when the result list component triggering the bulk delete action.
   */
  public bulkDelete(items: any) {
    const observables: any[] = [];

    items.forEach((item: any) => {
      if (item.toggled) {
        observables.push(this.resource.remove(item.id));
      }
    });

    forkJoin(observables)
      .subscribe(
        () => {
          this.snackbar.validate(this.translate('ALERTS.DATA.UPDATE'));
          this.state.go(`${this.resource.routeName}.list`, null, { reload: true });
        },
        () => this.snackbar.alert(this.translate('ALERTS.DATA.FAIL'))
      )
    ;
  }

  public delete(item: any): void {
    this.onCustomDelete.emit(item);
  }
}
