import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Resource } from '@decorators';
import { AbstractResource, IRequestOptions } from '../../resources/abstract.resource';
import { SessionHelper } from '../../helpers/session.helper';
import { SuperProductListModel } from '@components/super-product/models/super-product-list.model';
import { SuperProductFormModel } from '@components/super-product/models/super-product-form.model';
import { HttpClient } from '@angular/common/http';
import { ISuperProductSku } from '@components/super-product/interfaces/super-product-sku.interface';
import { map } from 'rxjs/operators';
import { IImportOptions } from '@interfaces/ImportOptions.interface';
import { HydraHelper } from '@helpers/HydraHelper';
import { SuperProductImportModel } from '@components/super-product/models/super-product-import.model';

export const PATH_V2 = '/api/v2/super_products';
const importOptions: IImportOptions[] = [
  {
    entryPoint: '/api/import/init',
    importButton: 'PAGE.IMPORT.BUTTON.SUPER_PRODUCT',
    businessObject: 'super_product',
    hideInfo: false,
    validationState: 'super-product.import'
  },
  {
    entryPoint: '/api/v2/import',
    importButton: 'BUTTON.IMPORT.CSV',
    businessObject: 'super-product',
  },
  {
    entryPoint: '/api/v2/import',
    importButton: 'BUTTON.DUPLICATE.CONTENT',
    businessObject: 'duplicate_super_product_content'
  },
];

@Injectable()
@Resource({
  routeName: 'super-product',
  entryPoint: '/superproducts',
  importOptions,
  cGetDefaultFilters: () => ({
    locale: SessionHelper.getLocale(),
    page: 1,
    byPage: 30,
    orderBy: '',
    direction: '',
    catalogId: undefined,
    categoryId: undefined,
    id: undefined,
    marketplace: undefined,
  }),
  translationKey: 'SUPER_PRODUCT',
  listColumns: [
    { field: 'skuParent', translationKey: 'PARENT_SKU', type: 'string', sortable: true },
    { field: 'reference', translationKey: 'REFERENCE', type: 'string', sortable: true },
    {
      field: 'catalog',
      translationKey: 'CATALOG',
      type: 'string',
      sortable: true,
      linkable: { state: 'catalogs.edit', fromParams: 'catalogId', toParams: 'id' },
    },
    { field: 'productSkus', translationKey: 'LINKED_SKU', type: 'labelled' },
  ],
  listModel: SuperProductListModel,
  formModel: SuperProductFormModel,
  customDeleteAvailable: true,
  buttonActionMultipleAvailable: true,
  deleteAvailable: false,
})
export class SuperProductResource extends AbstractResource {

  nullableProperties: string[] = ['marketplaceTitle', 'agTitle', 'repairabilityIndex'];

  constructor(
    http: HttpClient,
  ) {
    super(http);
  }

  /**
   * Finds super product in a specific locale.
   */
  public getByLocale(id: string, locale: string, options: any = {}): Observable<object> {
    return this.get(id, {
      ...options,
      params: { locale },
    });
  }

  /**
   * Gets sku list.
   */
  public getSkus(params?: any, options?: any): Observable<object> {
    return this.getMany(params, {
      ...options,
      entryPoint: `${this.entryPoint}/sku`,
      dontUseModel: true,
    })
      .pipe(
        map((response: ISuperProductSku[]) => {
          return response.map((item: ISuperProductSku) => {
            return {
              id: item.id,
              label: item.label,
              skuParent: item.skuParent,
              isHidden: item.isHidden,
              firstThumbnailPathFromFirstProduct: item.firstThumbnailPathFromFirstProduct
            };
          });
        })
      );
  }

  public putAttributeValues(superProductId: string, body?: object, options: any = {}) {
    options.entryPoint = `/v2/super_products/${superProductId}/attribute_values`;
    return this.update(superProductId, body, {
      ...options,
      dontUseModel: true,
    });
  }

  public duplicate(
    fromSuperProductId: number,
    toSuperProductId: number,
    fromCountryId: number,
    toCountryId: number,
    openAiTranslation: boolean,
    options?: object): Observable<Object> {
    return this.create({
      fromSuperProduct: HydraHelper.buildIri(fromSuperProductId, 'super_products'),
      toSuperProduct: HydraHelper.buildIri(toSuperProductId, 'super_products'),
      fromCountry: HydraHelper.buildIri(fromCountryId, 'countries'),
      toCountry: HydraHelper.buildIri(toCountryId, 'countries'),
      openAiTranslation
    }, {
      ...options,
      entryPoint: '/v2/super_products/duplicate'
    });
  }
  public duplicateNewContent(
    fromSuperProductId: number,
    toSuperProductId: number,
    fromCountryId: number,
    toCountryId: number,
    openAiTranslation: boolean,
    options?: object): Observable<Object> {
    return this.create({
      fromSuperProduct: HydraHelper.buildIri(fromSuperProductId, 'super_products'),
      toSuperProduct: HydraHelper.buildIri(toSuperProductId, 'super_products'),
      fromCountry: HydraHelper.buildIri(fromCountryId, 'countries'),
      toCountry: HydraHelper.buildIri(toCountryId, 'countries'),
      openAiTranslation
    }, {
      ...options,
      entryPoint: '/v2/super_products/duplicate_new_website'
    });
  }

  public retrieveSuperProductImport(importId: string, options?: IRequestOptions): Observable<any> {
    return this.get(importId, {
      ...options,
      model: SuperProductImportModel,
      isHydra: true,
      entryPoint: `/import/super_product/${importId}`,
    });
  }
}
